<template>
  <div>
    <b-modal
      id="code-check-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
      centered
    >
      <div class="header">
        <span class="close-icon"
              @click="$bvModal.hide('code-check-modal')">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>
          Подтверждение
        </h3>

        <p>
          На Ваш номер телефона поступит звонок. Введите 4 <br> цифры, продиктованные в звонке
        </p>
      </div>

      <div>
        <div>
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            separator=""
            :num-inputs="4"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-complete="handleOnComplete"
          />
        </div>

        <div v-if="codeError"
             class="error">{{ codeErrorResponse }}</div>

        <div class="resubmit-code"
             @click="resubmitCode">Получить звонок повторно</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters, mapMutations } from "vuex"

  export default {
    data() {
      return {
        code: '',
        codeError: false,
        sent: false,
        codeErrorResponse: ''
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        token: 'token',
        voucherData: 'voucherData'
      }),

      disabledBtn(){
        if (this.code.length < 4 || this.sent)
          return true
      }
    },

    methods: {
      ...mapMutations({
        saveUser: 'saveUser',
        saveToken: 'saveToken',
        getSuccessText: 'getSuccessText',
        getErrorText: 'getErrorText',
      }),

      handleOnComplete(value) {
        this.code = value

        this.submit()
      },

      resubmitCode(){
        axios.post('voix/send', {
          phone: this.user.phone,
        }, {
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'access-control-allow-origin': "*",
          }
        }).then((response) => {
        }).catch((error) => {
          if (error.response.data.message){
            this.getErrorText(error.response.data.message)

            this.$bvModal.show('error-modal')
          }
        })
      },

      submit(){
        this.sent = true

        axios.post('voix/check', {
          phone: this.user.phone,
          code: this.code
        }, {
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'access-control-allow-origin': "*",
          }
        }).then((response) => {
          this.submitReceipt()
        }).catch((error) => {
          this.codeError = true
          this.sent = false
          this.codeErrorResponse = error.response.data.message
        })
      },

      submitReceipt() {
        const formData = new FormData()
        formData.append('fp', this.voucherData.fp)
        formData.append('fn', this.voucherData.fn)
        formData.append('i', this.voucherData.fd)
        formData.append('t', this.voucherData.date + ' ' + this.voucherData.time + ':00')
        formData.append('s', this.voucherData.s)

        axios.post('receipt', formData, {
        }, {
          headers: {
            'Accept': "application/json",
            'Content-Type': "application/json",
            'access-control-allow-origin': "*",
            'Authorization': "Bearer " + this.token
          }
        }).then((response) => {
          this.sent = false
          this.$bvModal.hide('code-check-modal')
          this.$bvModal.show('success-upload-modal')
        }).catch((error) => {
          this.sent = false

          if (error.response.data.message === "Время жизни сессии истекло. Пожалуйста, авторизуйтесь"){
            this.getErrorText(error.response.data.message)

            this.logout()
            this.$bvModal.hide('code-check-modal')
            this.$bvModal.show('error-modal')
          }

          if (error.response.data.message){
            this.getErrorText(error.response.data.message)
            this.$bvModal.hide('code-check-modal')

            this.$bvModal.show('error-modal')
          }
        })
      },

      logout(){
        this.saveToken(null)
        this.saveUser(null)
        localStorage.removeItem('token')
      }
    }
  }
</script>

<style scoped lang="scss">
.header {
  flex-direction: column;
  width: 100%;

  .close-icon {
    float: right;
    cursor: pointer;
    transition: .4s all;
    margin-right: 5px;

    &:hover {
      opacity: .85;
    }
  }

  h3, p {
    text-align: center;
    font-family: $regular;
    color: $white;
  }

  h3 {
    margin-bottom: 15px !important;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;

    @media(max-width: 576px){
      font-size: 14px;
    }
  }

  h5 {
    width: 100%;
    color: $white;
    margin-top: 70px !important;
    margin-bottom: 10px;
    font-weight: 500;
    font-family: $regular;
    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 30px;
  }
}

.resubmit-code {
  font-family: $regular;
  cursor: pointer;
  margin-top: 25px;
  color: $white;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  width: 100%;
  text-decoration: underline;
  margin-bottom: 25px;
}

.button {
  width: 100%;
  max-width: 350px;
  margin-top: 30px;
}

.error {
  font-size: 10px;
  text-align: center;
  font-weight: 700;
  color: red;
}
</style>
