<template>
  <section class="section-wrapper"
           id="prizes">

    <div class="plug-wrapper">
      <div class="plug">
        <div class="plug-step">
          <div class="step">1</div>

          <div class="d-flex flex-column justify-content-between">
            <p class="title">
              покупайте линзы TOTAL1, <br>
              PRECISION1, AIR OPTIX <br>
              plus HYDRAGLYDE
            </p>

            <p class="subtext">
              c 1 августа по 30 сентября 2023 года
            </p>
          </div>
        </div>

        <div class="plug-step">
          <div class="step">2</div>

          <div class="d-flex flex-column justify-content-between">
            <p class="title underline">
              регистрируйте <br> чеки
            </p>

            <p class="subtext">
              в промо не участвуют <br>
              чеки из маркетплейсов*
            </p>
          </div>
        </div>

        <div class="plug-step">
          <div class="step">3</div>

          <div class="d-flex flex-column justify-content-between">
            <p class="title">
              получайте <br>
              гарантированные <br>
              призы <br class="desktop"> до 1000 рублей**
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="prizes-wrapper">
      <div class="prize-wrapper">
        <img src="@/assets/gift-1.png"
             class="prize-img"
        />

        <div class="d-flex align-items-center img-text">
          <p class="number">
            3
          </p>

          <p>
            стайлера
            <span class="highlighted">dyson</span>
            <br> каждый месяц
          </p>
        </div>
      </div>

      <div class="prize-wrapper mb-1">
        <h3 class="prize-title">
          покупайте больше <br>
          и участвуйте <br class="mobile-br"> в розыгрыше <br>
          главных призов
        </h3>

        <h3 class="prize-subtext">
          розыгрыш проводится среди самых <br> активных участников
        </h3>

        <!-- <router-link class="button button-orange mt-4" to="winners">
          Посмотреть ТОП-10 претендентов
        </router-link> -->
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Prizes',

  data: () => ({
  }),

  computed: {
  },
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-color: $white;
  padding-top: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0;

  @media(max-width: 1200px){
    padding-top: 30px;
  }

  @media(max-width: 999px){
    padding-left: 35px;
    padding-right: 35px;
  }

  @media(max-width: 756px){
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.prizes-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: $regular;
  max-width: 1400px;
  padding-top: 120px;
  padding-bottom: 120px;

  @media(max-width: 1600px){
    max-width: 1200px;
    justify-content: center;
  }

  @media(max-width: 999px){
    margin-top: 20px;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media(max-width: 760px){
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media(max-width: 576px){
    padding-top: 30px;
    padding-bottom: 60px;
    margin-top: 0;
  }

  .prize-wrapper {
    display: flex;
    flex-direction: column;

    @media(max-width: 760px){
      align-items: center;
      width: 100%;
    }

    &:first-child {
      align-items: center;
      margin-right: 100px;

      @media(max-width: 1200px){
        margin-right: 0;
        margin-bottom: 20px;
      }
    }


    .prize-img {
      z-index: 1;
      max-height: 360px;
      margin-right: 40px;

      @media(max-width: 1600px){
        max-height: 290px;
        margin-right: 0;
      }

      @media(max-width: 1200px){
        max-height: 210px;
        margin-right: 60px;
      }

      @media(max-width: 576px){
        max-width: 220px;
        margin-right: 0;
      }
    }

    .prize-title {
      font-size: 54px;
      color: $dark-blue;
      font-weight: 700;
      font-family: $regular;
      line-height: 60px;
      text-transform: uppercase;
      margin-bottom: 20px;

      @media(max-width: 1600px){
        font-size: 46px;
        line-height: 50px;
      }

      @media(max-width: 1200px){
        font-size: 32px;
        line-height: 32px;
        margin-top: 35px;
      }

      @media(max-width: 999px){
        font-weight: 700;
        font-size: 28px;
        line-height: 110%;
      }

      @media(max-width: 756px){
        font-size: 22px;
        font-family: $regular;
        line-height: 100%;
        width: 100%;

        br {
          display: block;
        }
      }

      @media(max-width: 576px){
        text-align: center;
      }
    }

    .prize-subtext {
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      color: $dark-blue;
      width: 100%;

      @media(max-width: 1200px){
        font-size: 20px;
      }

      @media(max-width: 768px){
        font-size: 14px;
        line-height: 100%;
        width: 100%;
        font-family: $regular;
      }

      @media(max-width: 576px){
        text-align: center;
      }
    }
  }
}

.mobile-br {
  @media(max-width: 756px){
    display: block !important;
  }
}

.button {
  max-width: 250px;
  font-size: 18px;
  height: 54px;
  line-height: 18px;
}

.img-text {
  margin-top: 30px;
  font-family: $regular;

  @media(max-width: 768px){
    font-family: $regular;
  }

  p {
    margin-bottom: 0;
    text-transform: uppercase;
    color: $dark-blue;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;

    @media(max-width: 1200px){
      font-size: 22px;
    }

    @media(max-width: 768px){
      font-weight: 700;
      font-size: 19px;
      line-height: 116%;
    }

    &:first-child {
      margin-right: 13px;
      font-size: 72px;
      line-height: 50px;

      @media(max-width: 768px){
        font-weight: 32px;
        font-size: 46px;
      }
    }
  }

  span {
    background-color: #8dccf3;
    padding: 0 3px;
  }
}

.plug-wrapper {
  width: 100%;
  border-radius: 40px;
  margin-top: -160px;
  justify-content: center;
  display: flex;
  max-width: 1550px;
  padding: 70px 20px;
  background: linear-gradient(0deg, #0E378F 0%, #0E378F 100%), linear-gradient(45deg, #0E378F 0%, #121D48 100%);
  box-shadow: 0px 20px 30px -20px rgba(46, 36, 123, 0.35), 0px 10px 20px -20px #2E247B, 0px -6px 32px 0px rgba(9, 147, 178, 0.51);

  @media(max-width: 999px){
    padding: 26px 15px;
    background: linear-gradient(45deg, #0E378F 0%, #121D48 100%);
    box-shadow: 0px -11px 32px 0px rgba(9, 147, 178, 0.51);
    margin-top: -120px;
    align-items: center;
  }

  @media(max-width: 576px){
    padding: 25px 10px;
  }
}

.plug {
  display: flex;
  color: $white;
  max-width: 1400px;
  width: 100%;
  justify-content: space-between;

  @media(max-width: 1600px){
    max-width: 1200px;
  }

  @media(max-width: 760px){
    flex-direction: column;
    padding: 26px 15px;
    align-items: center;
  }

  @media(max-width: 576px){
    padding: 25px 10px;
  }

  @media(max-width: 420px){
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .plug-step {
    display: flex;

    @media(max-width: 999px){
      margin-left: 0;
      margin-right: 0;
      margin-top: 15px;
      margin-bottom: 15px;
      width: 285px;
    }

    @media(max-width: 360px){
      width: 250px;
    }
  }

  .step {
    background-color: $white;
    border-radius: 50%;
    color: $darker-blue;
    font-weight: 700;
    font-size: 28px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 15px;

    @media(max-width: 999px){
      width: 24px;
      height: 24px;
      margin-right: 7px;
      font-size: 14px;
    }
  }

  p {
    margin-bottom: 0;
  }

  .title {
    font-size: 26px;
    line-height: 100%;
    font-weight: 700;
    font-family: $regular;
    text-transform: uppercase;
    margin-bottom: 7px;

    @media(max-width: 1600px){
      font-size: 22px;
    }

    @media(max-width: 1200px){
      font-size: 18px;
    }

    @media(max-width: 999px){
      font-size: 16px;
      font-weight: 700;
      line-height: 110%;
      font-family: $regular;
    }
  }

  .subtext {
    line-height: 106%;

    @media(max-width: 768px){
      font-size: 14px;
      line-height: 100%;
      font-family: $regular;
    }
  }
}
</style>
