<template>
  <header class="">
    <div class="header-wrapper">
      <div class="desktop d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <a class="header-link promo-logo"
             target="_blank"
             href="https://www.moiglaza.ru/">
            <img src="@/assets/svg/promo-logo.svg" />
          </a>

          <a href="http://www.moiglaza.ru/club"
             target="_blank"
             class="header-link club-logo">
            <img src="@/assets/logo.png" />
          </a>
        </div>

        <div class="d-flex align-items-center">
          <router-link :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'"
                       class="header-link">
            Условия участия
          </router-link>

          <router-link :to="{ path: '/#prizes' }"
                       v-scroll-to="'#prizes'"
                       class="header-link">
            Призы
          </router-link>

          <router-link :to="{ path: '/winners' }"
                       v-scroll-to="'#winners'"
                       class="header-link">
            Победители
          </router-link>

          <div class="profile"
               v-if="user"
               @click="openOffice">
            <img src="@/assets/svg/profile.svg" />
          </div>

          <router-link :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'"
                       class="profile"
                       v-if="!user">
            <img src="@/assets/svg/profile.svg" />
          </router-link>
        </div>
      </div>

      <div class="mobile d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <a class="header-link promo-logo"
             target="_blank"
             href="https://www.moiglaza.ru/">
            <img src="@/assets/svg/promo-logo@mobile.svg" />
          </a>

          <a href="http://www.moiglaza.ru/club"
             target="_blank"
             class="header-link club-logo">
            <img src="@/assets/logo.png" />
          </a>
        </div>

        <div class="d-flex align-items-center">
          <div class="profile"
               v-if="user"
               @click="openOffice">
            <img src="@/assets/svg/profile.svg" />
          </div>

          <router-link :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'"
                       class="profile"
                       v-if="!user">
            <img src="@/assets/svg/profile.svg" />
          </router-link>

          <div class="mobile-icon"
               @click="mobileMenuOpened = !mobileMenuOpened">
            <img src="@/assets/svg/burger-opened.svg"
                 v-if="mobileMenuOpened" />

            <img src="@/assets/svg/burger-icon.svg"
                 v-if="!mobileMenuOpened" />
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-menu"
         :class="{ active: mobileMenuOpened }">
       <router-link :to="{ path: '/#mechanics' }"
                    v-scroll-to="'#mechanics'"
                    class="header-link"
                    @click.native="mobileMenuOpened = !mobileMenuOpened">
         Условия участия
       </router-link>

       <router-link :to="{ path: '/#prizes' }"
                    v-scroll-to="'#prizes'"
                    class="header-link"
                    @click.native="mobileMenuOpened = !mobileMenuOpened">
         Призы
       </router-link>

       <router-link :to="{ path: '/winners' }"
                    v-scroll-to="'#winners'"
                    class="header-link"
                    @click.native="mobileMenuOpened = !mobileMenuOpened">
         Победители
       </router-link>

       <!-- <button class="button button-orange mt-4">
         Личный кабинет
       </button> -->
    </div>
  </header>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from "vuex"

export default {
  name: 'HeaderRegion',

  data: () => ({
    mobileMenuOpened: false,
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    }),
  },

  methods: {
    ...mapMutations({
      saveUser: 'saveUser',
      saveToken: 'saveToken',
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText',
    }),

    openOffice(){
      this.$router.push('/office')
    },

    logout(){
      this.saveToken(null)
      this.saveUser(null)
      localStorage.removeItem('token')
    }
  }
}
</script>

<style scoped lang="scss">
header {
  position: relative;
  width: 100%;
  z-index: 100;
}

.header-wrapper {
  height: 68px;
  z-index: 999;
  font-family: $regular;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $darker-blue;
  padding-left: 35px;
  padding-right: 160px;

  @media(max-width: 1200px){
    padding: 0 25px;
  }

  @media(max-width: 999px){
    height: 72px;
    padding: 0 10px;
    position: fixed;
    width: 100%;
  }
}

.promo-logo {
  border-right: 1px solid $white;
  padding-right: 20px;
  margin-right: 15px !important;

  @media(max-width: 999px){
    border-right: 1px solid #3d5ba5;
    padding-right: 15px;
    margin-right: 10px !important;
  }
}

.profile {
  margin-left: 55px;
  cursor: pointer;
  transition: .5s all;

  &:hover {
    opacity: .9;
  }

  @media(max-width: 999px){
    margin-left: 0;

    img {
      max-width: 24px;
    }
  }
}

.club-logo {
  img {
    max-width: 145px;
    max-height: 30px;
  }
}

.header-link {
  cursor: pointer;
  color: $white;
  font-family: $regular;
  font-weight: 400 !important;
  text-decoration: none;
  font-size: 18px;
  line-height: 100%;
  transition: .4s all ease;
  margin-right: 35px;

  &:hover {
    opacity: .85 !important;
  }

  &:last-child {
    margin-right: 0;
  }

  @media(max-width: 1400px){
    margin-right: 15px;
    font-size: 14px;
  }

  img {
    max-width: 350px;

    @media(max-width: 768px){
      max-width: 236px;
    }
  }
}

.router-link-exact-active {
  font-weight: 700;
}

.mobile-icon {
  transition: 1s all;
  margin-left: 15px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: none;
  margin-left: 15px;
  align-items: center;
  justify-content: center;

  @media(max-width: 999px){
    display: flex;
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.9;
  }

  img {
    margin: 0;
    transition: 1s all;
  }
}

.mobile-menu {
  background-color: $darker-blue;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 25px;
  position: fixed;
  width: 100vw;
  margin-top: -400px;
  transition: .8s all ease;
  z-index: 999;

  .header-link {
    font-size: 16px;
    display: block !important;
    margin-top: 20px;
    font-weight: 500 !important;
    margin-left: 0;
    margin-right: 0 !important;

    &:first-child {
      margin-top: 0;
    }
  }

  &.active {
    margin-top: 0px;
    border-bottom: 1px solid #869BC7;
    box-shadow: 0px 4px 13px 0px rgba(18, 31, 76, 0.54);


    @media(max-width: 999px){
      margin-top: 70px;
    }
  }
}

.desktop {
  width: 100%;

  @media(max-width: 999px){
    display: none !important;
  }
}

.mobile {
  width: 100%;

  display: none !important;

  @media(max-width: 999px){
    display: flex !important;
  }
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white !important;

  &:hover {
    text-decoration: none;
    color: $white !important;
  }
}
</style>
