<template>
  <footer>
    <div class="content-wrapper">
      <img src="@/assets/blue-gift.png"
           class="gift" />

      <div class="wrapper">
        <p class="text">
          Общий период проведения акции, включая период вручения призов: с 1 августа
          2023 г. по 31 октября 2023 г. включительно. Информация об организаторе,
          правилах проведения акции, количестве призов, сроках, месте и порядке их
          получения указаны в Правилах акции и размещены на сайте
          <a href="alcon-promo.ru" target="no_blank">alcon-promo.ru</a>
          Горячая линия Акции: 8-800-505-65-24.
          *В промо не принимаются чеки из следующих магазинов: ООО "ЯНДЕКС" (ИНН – 7736207543),
          ООО "Маркетплейс" (ИНН –9701048328), ООО "Вайлдберриз"(ИНН – 7721546864),
          ООО "Интернет Решения" (ИНН – 7704217370).
          **Гарантированным призом является подарочный сертификат Digift
          до 1000 рублей. По всем вопросам, касающимся продуктов/работ/услуг
          по сертификатам Digift необходимо обращаться непосредственно в компанию,
          предоставляющую продукты/работы/услуги по сертификатам Digift, либо в ООО
          «Дигифт» (ИНН 7814647858). Внешний вид приза может отличаться.
          ООО «Алкон Фармацевтика», 125315, r. Москва, Ленинградский пр-т, 72, корп.
          3. Тел.: +7 (495) 755-68-69; +7 (495) 961-13-33.  Факс: +7 (495) 961-13-33. RU-DT1-2300011
        </p>

        <div class="links">
          <a :href="rules[0].url"
             v-if="rules"
             target="no_blank"
          >
            Правила акции
          </a>

          <div v-b-modal.feedback-modal>Обратная связь</div>
        </div>
      </div>

      <p class="subtext">
        имеются противопоказания, необходимо проконсультироваться со специалистом
      </p>
    </div>
  </footer>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex"

export default {
  name: 'FooterRegion',

  data: () => ({
  }),

  computed: {
    ...mapGetters({
      rules: 'rules',
      policies: 'policies'
    }),
  },
}
</script>

<style scoped lang="scss">
footer {
  background-color: $darker-blue;
  width: 100%;
  height: 100%;
  padding: 22px 60px;
  display: flex;
  justify-content: center;
  background: url('@/assets/footer-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 50px;

  @media(max-width: 768px){
    padding: 16px;
    padding-top: 25px;
  }

  @media(max-width: 576px){
    padding: 0 20px;
    padding-top: 25px;
  }
}

.text {
  color: $white;
  font-size: 10px;
  font-weight: 400;
  font-family: $regular;
  margin-bottom: 2px;
  text-align: justify;

  a {
    color: $white;
    font-weight: 500;
  }
}

.links {
  color: $white;
  white-space: nowrap;
  font-size: 14px;
  margin-left: 40px;
  font-family: 'Roboto', sans-serif;

  @media(max-width: 756px){
    display: flex;
    justify-content: space-between;
    margin-left: 0;
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 15px;
  }

  div {
    cursor: pointer;
    transition: .5s all;

    @media(max-width: 768px){
      margin-left: 0;
    }

    &:hover {
      opacity: .8;
    }
  }

  a {
    color: $white;
    display: block;
    transition: .5s all;

    &:hover {
      opacity: .8;
      text-decoration: none;
    }
  }
}

.content-wrapper {
}

.wrapper {
  display: flex;
  padding: 0;
  position: relative;
  z-index: 10;

  @media(max-width: 756px){
    flex-direction: column-reverse;
  }
}

.subtext {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  color: $white;
  font-family: 'Open Sans Condensed', sans-serif;
  opacity: .3;
  width: 100%;
  margin-top: 15px;
  letter-spacing: 1.5px;
  margin-bottom: 0;
  text-align: center;

  @media(max-width: 1600px){
    font-size: 32px;
  }

  @media(max-width: 1500px){
    letter-spacing: 2.3px;
  }

  @media(max-width: 1200px){
    font-size: 22px;
    letter-spacing: 2.6px;
  }

  @media(max-width: 999px){
    font-size: 18px;
    letter-spacing: 2.1px;
    margin-top: 10px;
    line-height: 90%;
  }

  @media(max-width: 576px){
    font-size: 17px;
    letter-spacing: 2.45px;
    line-height: 110%;
  }

  @media(max-width: 375px){
    letter-spacing: 2px;
    font-size: 16px;
  }

  @media(max-width: 360px){
    letter-spacing: 2.1px;
    font-size: 15px;
  }

  @media(max-width: 340px){
    letter-spacing: 1.4px;
    font-size: 14px;
  }
}

.gift {
  max-width: 280px;
  position: absolute;
  right: 10px;
  top: -220px;
  z-index: 1;

  @media(max-width: 768px){
    display: none;
  }
}
</style>
