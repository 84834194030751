<template>
  <section class="section-wrapper"
           id="mechanics">
    <h3 class="section-title">3 простых шага <br> для участия</h3>

    <img src="@/assets/dark-gift.png" class="gift" />

    <img src="@/assets/light-gift.png" class="light-gift" />

    <div class="content-wrapper desktop">
      <div class="steps">
        <div class="d-flex step step__1 mb-3">
          <div>
            Авторизуйтесь с помощью <br>
            аккаунта Клуба <br>
            привилегий "МоиГлаза" <br>
            или зарегистрируйтесь
          </div>

          <img src="@/assets/svg/step-1@wide.svg" class="wide" />

          <img src="@/assets/svg/step-1.svg" class="normal" />
        </div>

        <div class="d-flex step step__2 halfopacity"
             :class="{ opacity : user }">
          <div style="margin-bottom: -10px;" class="column-text">
            <div>
              загрузите чек о покупке <br>
              ЛИНЗ TOTAL1, PRECISION1 <br>
              ИЛИ AIR OPTIX PLUS HYDRAGLYDE
            </div>

            <div>
              Получайте гарантированный
              <strong>приз <br> до 1000 рублей**</strong>
              и участвуйте <br> в розыгрыше
              <strong>главных призов</strong>
            </div>
          </div>

          <img src="@/assets/svg/step-2@wide.svg" class="wide" style="margin-right: 5px;" />

          <img src="@/assets/svg/step-2.svg" class="normal" />
        </div>
      </div>

      <div class="action">
        <div class="d-flex mb-3 action-wrapper action-wrapper__registration"
             v-if="(!user && registration) || (!user && unregisteredPhone)">
          <div>
            <div class="options">
              <span @click="openLogin" style="">Авторизация</span>

              <!-- <span @click="openRegistration" style="text-decoration: underline !important;">Регистрация</span> -->
            </div>

            <Registration />
          </div>
        </div>

        <div class="d-flex mb-3 action-wrapper"
             v-if="(!user && login && !unregisteredPhone)">
          <div>
            <div class="options">
              <span @click="openLogin" style="text-decoration: underline !important;">Авторизация</span>

              <!-- <span @click="openRegistration">Регистрация</span> -->
            </div>

            <PhoneLogin />
          </div>
        </div>

        <div class="d-flex mb-3 action-wrapper action-wrapper__upload"
             v-if="user">
          <div>
            <p class="w-100 text-center">Загрузка чека</p>

            <div style="margin-top: 40px; font-size: 16px; text-align: center;">Акция завершена</div>

            <!-- <div class="d-flex step step__3 step__upload">
              <div style="opacity: 1 !important;"
                   class="w-100">

                <div class="upload-options">
                  <div class="upload-option">
                    <button class="button button-white"
                            onclick="ym(94436170,'reachGoal','QR')"
                            v-b-modal.upload-voucher-modal>
                      Сканировать QR-код
                    </button>
                  </div>

                  <div class="upload-option">
                    <button class="button button-white"
                            onclick="ym(94436170,'reachGoal','check')"
                            v-b-modal.cash-voucher-modal>
                      Ввести данные вручную
                    </button>
                  </div>

                  <div class="upload-option"
                       onclick="ym(94436170,'reachGoal','foto')">
                    <label style="z-index: 2;">
                      <input type="file"
                             @change="onDecode"
                             accept="image/jpeg, image/png, image/jpg">

                      <span class="button button-white" style="text-transform: none;">Загрузить фотографию</span>
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <p class="sub-title-text">
                  Обратите внимание:
                </p>

                <p>
                  1. На фотографии чека должен чётко <br> читаться QR-код; <br>
                  2. Файл должен быть в формате JPEG или <br> PNG, размером не более 20мБ
                </p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="content-wrapper mobile">
      <div class="steps">
        <div class="d-flex step step__1 mb-3">
          <img src="@/assets/svg/step-1@mobile.svg" />

          <div>
            Авторизуйтесь <br> с помощью <br>
            аккаунта Клуба <br>
            привилегий "МоиГлаза" <br>
            или зарегистрируйтесь
          </div>
        </div>

        <div class="d-flex mb-3 action-wrapper action-wrapper__registration"
             v-if="(!user && registration) || (!user && unregisteredPhone)">
          <div>
            <div class="options">
              <span @click="openLogin">Авторизация</span>

              <!-- <span @click="openRegistration" style="text-decoration: underline !important;">Регистрация</span> -->
            </div>

            <Registration />
          </div>
        </div>

        <div class="d-flex mb-3 action-wrapper"
             v-if="(!user && login && !unregisteredPhone)">
          <div>
            <div class="options">
              <span @click="openLogin" style="text-decoration: underline !important;">Авторизация</span>

              <!-- <span @click="openRegistration">Регистрация</span> -->
            </div>

            <PhoneLogin />
          </div>
        </div>

        <div class="d-flex step step__2 halfopacity"
             :class="{ opacity : user }">
          <div style="margin-bottom: -10px;" class="column-text">
            <div class="d-flex">
              <div class="number">
                2
              </div>

              <div class="">
                загрузите чек о покупке <br>
                ЛИНЗ TOTAL1, PRECISION1 <br>
                ИЛИ AIR OPTIX PLUS HYDRAGLYDE
              </div>
            </div>

            <div class="action">
              <div class="d-flex mb-3 action-wrapper action-wrapper__upload"
                   v-if="user">
                <div>
                  <p class="w-100 text-center check-title">Загрузка чека</p>

                  <div style="margin-top: 40px; font-size: 16px; text-align: center;">Акция завершена</div>

                  <!-- <div class="d-flex step step__3 step__upload">
                    <div style="opacity: 1 !important;"
                         class="w-100">

                      <div class="upload-options">
                        <div class="upload-option">
                          <button class="button button-white"
                                  onclick="ym(94436170,'reachGoal','QR')"
                                  v-b-modal.upload-voucher-modal>
                            Сканировать QR-код
                          </button>
                        </div>

                        <div class="upload-option">
                          <button class="button button-white"
                                  onclick="ym(94436170,'reachGoal','check')"
                                  v-b-modal.cash-voucher-modal>
                            Ввести данные вручную
                          </button>
                        </div>

                        <div class="upload-option"
                             onclick="ym(94436170,'reachGoal','foto')">
                          <label style="z-index: 2;">
                            <input type="file"
                                   @change="onDecode"
                                   accept="image/jpeg, image/png, image/jpg">

                            <span class="button button-white" style="text-transform: none;">Загрузить фотографию</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <!-- <div>
                      <p class="sub-title-text">
                        Обратите внимание:
                      </p>

                      <p>
                        1. На фотографии чека должен чётко <br> читаться QR-код; <br>
                        2. Файл должен быть в формате JPEG или <br> PNG, размером не более 20мБ
                      </p>
                    </div> -->
                  </div> -->
                </div>
              </div>
            </div>

            <div class="d-flex">
              <div class="number">
                3
              </div>

              <div>
                Получайте <br> гарантированный
                <strong>приз <br> до 1000 рублей**</strong>
                и участвуйте в розыгрыше
                <strong>главных призов</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import QrScanner from 'qr-scanner'
import PhoneLogin from '@/components/PhoneLogin'
import Registration from '@/components/Registration'
import { mapState, mapGetters, mapMutations } from "vuex"

export default {
  name: 'Mechanics',

  data: () => ({
    item: {
      fp: '',
      fn: '',
      fd: '',
      date: '',
      time: '',
      s: ''
    },
    files: [],
    login: true,
    registration: false,
  }),

  components: {
    PhoneLogin,
    Registration,
  },

  computed: {
    ...mapGetters({
      user: 'user',
      unregisteredPhone: 'unregisteredPhone'
    }),
  },

  methods: {
    ...mapMutations({
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText',
      saveVoucherDataFp: 'saveVoucherDataFp',
      saveVoucherDataFd: 'saveVoucherDataFd',
      saveVoucherDataFn: 'saveVoucherDataFn',
      saveVoucherDataT: 'saveVoucherDataT',
      saveVoucherDataS: 'saveVoucherDataS',
      saveVoucherDataDate: 'saveVoucherDataDate',
      saveVoucherDataTime: 'saveVoucherDataTime',
    }),

    openLogin(){
      this.login = true
      this.registration = false
    },

    openRegistration(){
      this.registration = true
      this.login = false
    },

    saveQrData(){
      this.saveVoucherDataFp(this.item.fp)
      this.saveVoucherDataFn(this.item.fn)
      this.saveVoucherDataFd(this.item.fd)
      this.saveVoucherDataS(this.item.s)
      this.saveVoucherDataT(this.item.t)
      this.saveVoucherDataDate(this.item.date)
      this.saveVoucherDataTime(this.item.time)
    },

    submitReceipt() {
      const formData = new FormData()
      formData.append('fp', this.item.fp)
      formData.append('fn', this.item.fn)
      formData.append('i', this.item.fd)
      formData.append('t', this.item.date + ' ' + this.item.time + ':00')
      formData.append('s', this.item.s)

      axios.post('receipt', formData, {
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
        this.getCode()
      }).catch((error) => {
        if (error.response.data.message === "Время жизни сессии истекло. Пожалуйста, авторизуйтесь"){
          this.getErrorText(error.response.data.message)

          this.logout()
          this.$bvModal.show('error-modal')
        }

        if (error.response.data.message){

          this.$bvModal.show('failed-voucher-modal')
        }
      })
    },

    logout(){
      this.saveToken(null)
      this.saveUser(null)
      localStorage.removeItem('token')
    },

    onDecode (event) {
      QrScanner.scanImage(event.target.files[0])
        .then(result => {
          let newDate

          this.qrData = result.split('&')

          newDate = this.qrData.map(value =>{
            return value.split('=')
          })
          newDate.forEach(value=>{
            if(value[0] === 'fn'){
              this.item.fn = value[1]
            }
            if(value[0] === 'fp'){
              this.item.fp = value[1]
            }
            if(value[0] === 'i'){
              this.item.fd = value[1]
            }
            if(value[0] === 's'){
              this.item.total = value[1]
            }
            if(value[0] === 't'){
              this.t = value[1]
              let chDate = value[1].split('T')[0].split('')
              let chTime = value[1].split('T')[1].split('')

              this.item.date = chDate[6]+chDate[7]+'.'+chDate[4]+chDate[5]+'.'+chDate[0]+chDate[1]+chDate[2]+chDate[3],
              this.item.time = chTime[0]+chTime[1]+':'+chTime[2]+chTime[3]
            }
          })
          let reader = new FileReader();
          reader.onload = (e) => {
            this.files.push({
              src: e.target.result,
              correct: true,
              data: event.target.files[0]
            })
          };
          reader.readAsDataURL(event.target.files[0])
          this.saveQrData()
        })
        .catch((error) => {
          console.log(error)
          let reader = new FileReader();
          reader.onload = (e) => {
            this.files.push({
              src: e.target.result,
              correct: false,
              data: event.target.files[0]
            })
          }
        reader.readAsDataURL(event.target.files[0])
        this.saveQrData()
      })

      this.$bvModal.show('failed-voucher-modal')
    },

    getCode(){
      axios.post('voix/send', {
        phone: this.user.phone,
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
        }
      }).then((response) => {
        this.$bvModal.show('code-check-modal')
      }).catch((error) => {
        this.$bvModal.show('error-modal')
        this.getErrorText(error.response.data.message)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-color: $darker-blue;
  background-repeat: no-repeat;
  padding-top: 150px;
  padding-bottom: 160px;
  background-position: right;
  color: $white;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-image: url('@/assets/mechanics-bg.webp');

  @media(max-width: 1200px){
    padding-left: 30px;
    padding-right: 30px;
  }

  @media(max-width: 999px){
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 70px;
    padding-bottom: 30px;
  }

  @media(max-width: 756px){
    background-size: cover;
    padding: 30px 10px;
    padding-top: 70px;
    background-image: url('@/assets/mechanics-bg@mobile.webp');
  }

  @media(max-width: 576px){
    padding: 30px 20px;
    padding-top: 70px;
  }
}

.section-title {
  text-align: center;

  br {
    display: none;
  }

  @media(max-width: 768px){
    br {
      display: block;
    }
  }
}

.content-wrapper {
  font-size: 24px;
  margin-top: 50px;

  @media(max-width: 768px){
    font-size: 16px;
    margin-top: 30px;
  }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 60px;

  .fake-btn {
    background-color: $orange;
    color: $white;
    font-family: $regular;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 32px;
    padding: 10px 50px;
    border-radius: 94px;
    text-align: center;

    @media(max-width: 768px){
      font-size: 18px;
      max-width: 270px;
    }
  }
}

.steps {
  margin-right: 80px;

  @media(max-width: 768px){
    margin-right: 0;
  }
}

.step {
  display: flex;
  justify-content: flex-end;

  .number {
    background-color: $white;
    color: $darker-blue;
    border-radius: 50%;
    margin-right: 15px;
    margin-left: 4px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 24px;
    max-height: 24px;
    font-size: 14px;
    margin-bottom: 0 !important;
    margin-top: 5px !important;
  }

  img {
    margin-left: 30px;
    flex-shrink: 0;

    @media(max-width: 1600px){
      margin-left: 17px;
    }

    @media(max-width: 768px){
      margin-left: 0;
      margin-right: 9px;
    }
  }

  div {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 32px;
    font-family: $regular;
    text-align: right;
    line-height: 118%;

    @media(max-width: 1600px){
      font-size: 24px;
    }

    @media(max-width: 768px){
      justify-content: flex-start;
      text-align: left;
      font-size: 17px;
      width: 100%;
      line-height: 110%;
    }
  }

  &__1 {
    div {
      margin-top: 5px;

      @media(max-width: 768px){
        margin-top: 0;
      }
    }
  }

  &__2 {
    div {

      &:first-child {
        margin-top: -18px;
        margin-bottom: 35px;

        @media(max-width: 1600px){
          margin-top: -10px;
        }
      }

      @media(max-width: 768px){
        margin-top: 0;

        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
  }

  &__3 {
    div {
      opacity: .8;
      margin-top: 6px;

      @media(max-width: 768px){
        margin-top: 11px;
      }
    }
  }

  &__upload {
    flex-direction: column;
    text-align: left;

    div {
      opacity: 1;
      margin-top: 3px;
      text-align: left;
      font-size: 16px;
      font-family: $regular;
      text-transform: none;
      font-weight: 400;
      line-height: 125%;

      p {
        margin-bottom: 0;
      }

      .sub-title-text {
        font-weight: 500;
        margin-bottom: 5px;
      }
    }
  }

  &__4 {
    div {
      @media(max-width: 768px){
        line-height: 19px;
        margin-top: 20px;
      }
    }
  }
}

.mechanics-decor {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 60px;
  margin-bottom: 165px;

  @media(max-width: 1300px){
    max-width: 300px;
  }

  @media(max-width: 1100px){
    max-width: 220px;
  }

  @media(max-width: 999px){
    display: none;
  }
}

.mobile {
  @media(max-width: 999px){
    display: block !important;
  }
}

.options {
  display: flex;
  justify-content: center;

  span {
    cursor: pointer;
    text-transform: none;
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
    font-family: $regular;
    color: $white;

    &:hover {
      opacity: .9;
    }

    @media(max-width: 768px){
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.upload-options {
  width: 100%;
  margin-top: 10px !important;

  @media(max-width: 999px){
    margin-top: 30px !important;
  }

  @media(max-width: 768px){
    margin-top: 25px !important;
  }

  .upload-option {
    display: flex;
    width: 100%;
    margin-bottom: 16px;

    @media(max-width: 768px){
      flex-direction: column;
      align-items: center;
    }

    .button {
      height: 48px;
      width: 100%;
      max-width: 350px;
      min-width: 350px;
      z-index: 2;
      font-size: 17px;

      @media(max-width: 999px){
        font-size: 14px;
      }

      @media(max-width: 768px){
        height: 48px;
        max-width: 275px;
        min-width: 275px;
      }

      @media(max-width: 420px){
        font-size: 14px;
        white-space: nowrap;
      }
    }

    .upload-tip {
      font-size: 13px;
      font-family: $regular;
      height: 65px;
      display: flex;
      align-items: center;
      border-radius: 96px;
      background-color: #0496dc;
      margin-left: -60px;
      margin-top: 0;
      z-index: 1;
      padding-left: 90px;
      padding-right: 35px;

      @media(max-width: 1200px){
        font-size: 11px !important;
      }

      @media(max-width: 768px){
        height: 70px;
        max-width: 290px;
        min-width: 290px;
        margin-left: 0;
        padding: 0;
        padding-top: 15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        justify-content: flex-start;
        padding-left: 16px;
        margin-top: -20px;
        font-size: 9px !important;
      }

      @media(max-width: 420px){
        max-width: 250px;
        min-width: 250px;
        font-size: 14px;
        white-space: nowrap;
      }

      &__long {
        @media(max-width: 768px){
          margin-top: -30px;
          height: 90px;
          padding-right: 30px;
          padding-top: 30px;
          padding-bottom: 10px;
        }
      }

      p {
        margin-bottom: 0;
        white-space: nowrap;
      }

      .long-tip {
        @media(max-width: 768px){
          white-space: normal;
        }
      }
    }
  }
}

input[type='file'] {
  position: absolute;
  opacity: 0;
  height: 0;
  z-index: 2;
}

.content-wrapper {
  display: flex;
  justify-content: flex-start;

  @media(max-width: 1600px){
    justify-content: center;
  }
}

.action {
  @media(max-width: 768px){
    margin-top: 15px !important;
  }
}

.action-wrapper {
  border-radius: 30px;
  border: 2px solid #FFF;
  background: rgba(255, 255, 255, 0.20);
  box-shadow: 0px -6px 32px 0px rgba(9, 147, 178, 0.51);
  padding: 50px 30px;
  width: 100%;
  justify-content: center;
  min-width: 340px;

  @media(max-width: 768px){
    padding-top: 32px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px !important;
  }

  &__upload {
    padding-top: 25px;
    padding-bottom: 25px;
    flex-direction: column;

    @media(max-width: 768px){
      padding-top: 32px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 0;
    }
  }

  &__registration {
    padding-bottom: 10px;
    padding-top: 25px;
  }
}

.halfopacity {
  opacity: 0.5;
}

.column-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.opacity {
  opacity: 1;
}

.check-title {
  text-transform: none;
  font-size: 18px;
}

.light-gift {
  position: absolute;
  left: 0;
  top: 150px;
  max-width: 220px;

  @media(max-width: 768px){
    display: none;
  }
}

.gift {
  position: absolute;
  right: 0;
  top: -160px;
  max-width: 250px;

  @media(max-width: 768px){
    top: -80px;
    max-width: 120px;
  }
}

.wide {
  @media(max-width: 1600px){
    display: none;
  }
}

.normal {
  display: none;

  @media(max-width: 1600px){
    display: block;
  }
}
</style>
