<template>
  <section class="section-wrapper">
    <div class="content-wrapper">
      <h3 class="section-title">Купите контактные линзы <br> на нашем сайте:</h3>

      <p class="subtext">
        Мы пришлем сертификат Digift на вашу электронную почту, чек регистрировать
        не надо. <br> Если вы являетесь участником Клуба привилегий "МоиГлаза",
        бонусные баллы будут начислены автоматически.
      </p>

      <div class="offer-wrapper">
        <div>
          <p class="offer-title">
            Сделайте заказ <br> в интернет-магазине
          </p>

          <p class="offer-subtext">Оформите заказ на нашем сайте</p>

          <a class="button button-orange"
             href="https://www.moiglaza.ru/products/all/"
             target="_blank">
             купить
          </a>
        </div>

        <div>
          <p class="offer-title">
            Купите линзы по подписке <br>
          </p>

          <p class="offer-subtext">
            Оформите подписку на линзы с единоразовым или <br class="desktop"> ежеквартальным получением
          </p>

          <a class="button button-orange"
             href="https://www.moiglaza.ru/subscription/"
             target="_blank">
             оформить подписку
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'BuyLenses',

  data: () => ({
  }),

  computed: {
  },
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-color: white;
  padding-top: 60px;
  padding-bottom: 40px;
  color: $darker-blue;
  display: flex;
  justify-content: center;

  @media(max-width: 999px){
    padding-left: 35px;
    padding-right: 35px;
  }

  @media(max-width: 756px){
    padding-bottom: 70px;
    padding-top: 15px;
    background-size: 420%;
    background-position: bottom -15px right;
  }

  @media(max-width: 576px){
    padding: 0 20px;
    padding-top: 15px;
    padding-bottom: 70px;
  }
}

.section-title {
  text-align: left;
  color: $darker-blue;
  font-weight: 700;
  font-family: $regular;

  br {
    display: none;
  }

  @media(max-width: 1200px){
    br {
      display: block;
    }
  }

  @media(max-width: 576px){
    font-size: 24px;

    br {
      display: none;
    }
  }
}

.subtext {
  color: $darker-blue;
  margin-bottom: 35px;
  font-size: 24px;
  font-weight: 400;
  font-family: $regular;
  text-align: left;

  @media(max-width: 756px){
    font-size: 14px;
    margin-bottom: 30px;

    br {
      display: none;
    }
  }
}

.offer-wrapper {
  display: flex;
  justify-content: flex-start;

  @media(max-width: 756px){
    flex-direction: column;
    align-items: flex-start;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &:first-child {
      margin-right: 160px;

      @media(max-width: 999px){
        margin-right: auto;
      }

      @media(max-width: 750px){
        margin-right: 0;
        margin-bottom: 32px;
      }
    }
  }

  .offer-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
    margin-bottom: 8px;
    text-align: left;

    @media(max-width: 999px){
      br {
        display: none;
      }
    }

    @media(max-width: 756px){
      br {
        display: block;
      }
    }

    @media(max-width: 756px){
      font-size: 20px;
      line-height: 26px;
    }
  }

  .offer-subtext {
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 24px;
    text-align: left;
  }

}

.button {
  color: $darker-blue;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
}
</style>
