import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import VueScrollTo from 'vue-scrollto'
import { BootstrapVue } from 'bootstrap-vue'
import OtpInput from "@bachdgvn/vue-otp-input"
import VueQrcodeReader from "vue-qrcode-reader"
import VueDebounce from 'vue-debounce'

import '@/styles/scss/_templates.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueTheMask)
Vue.use(VueScrollTo)
Vue.use(VueDebounce)
Vue.use(BootstrapVue)
Vue.use(VueQrcodeReader)
Vue.component("v-otp-input", OtpInput)

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL

// axios.defaults.baseURL = "https://develop-u2c-alcon-starfall-gifts-backend.dev.u2c.su/api/"

Vue.prototype.$http = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
