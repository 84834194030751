import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    user: null,
    rules: null,
    policies: null,
    certs: null,
    receipts: null,
    personalData: null,
    errorText: '',
    rating: [],
    successText: '',
    voucherData: {
      fp: '',
      fn: '',
      fd: '',
      t: '',
      s: '',
      date: '',
      time: ''
    },
    unregisteredPhone: '',
  },
  mutations: {
    saveUser(state, data) {
      state.user = data
    },
    saveToken(state, data) {
      state.token = data
    },
    getSuccessText(state, data) {
      state.successText = data
    },
    getErrorText(state, data) {
      state.errorText = data
    },
    setReceipts(state, data) {
      state.receipts = data
    },
    setRating(state, data) {
      state.rating = data
    },
    setCerts(state, data) {
      state.certs = data
    },
    saveRules(state, data){
      state.rules = data
    },
    savePersonalData(state, data){
      state.personalData = data
    },
    savePolicies(state, data){
      state.policies = data
    },
    saveUnregisteredPhone(state, data){
      state.unregisteredPhone = data
    },
    saveVoucherDataFp(state, data){
      state.voucherData.fp = data
    },
    saveVoucherDataFn(state, data){
      state.voucherData.fn = data
    },
    saveVoucherDataFd(state, data){
      state.voucherData.fd = data
    },
    saveVoucherDataT(state, data){
      state.voucherData.t = data
    },
    saveVoucherDataS(state, data){
      state.voucherData.s = data
    },
    saveVoucherDataDate(state, data){
      state.voucherData.date = data
    },
    saveVoucherDataTime(state, data){
      state.voucherData.time = data
    },
  },
  actions: {
    getUser({commit, payload}){
      axios.get('account', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
        commit('saveUser', response.data.data)
      })
    },

    getDocuments({commit}, payload) {
      axios.get('documents', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*"
        }
      }).then((response) => {
        commit('saveRules', response.data.data.filter(data => data.type == 'rules'))
        commit('savePolicies', response.data.data.filter(data => data.type == 'privacy_policy'))
        commit('savePersonalData', response.data.data.filter(data => data.type == 'personal_data'))
      })
    },

    getReceipts({commit}){
      axios.get('receipt', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
          commit('setReceipts', response.data.data)
      })
    },

    getRating({commit}){
      axios.get('rating?per_page=10', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
        }
      }).then((response) => {
          commit('setRating', response.data.data)
      })
    },

    getCerts({commit}){
      axios.get('certs', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
          commit('setCerts', response.data.data)
      })
    },
  },
  getters : {
    user: state => {
      return state.user
    },
    token: state => {
      return state.token
    },
    errorText: state => {
      return state.errorText
    },
    receipts: state => {
      return state.receipts
    },
    certs: state => {
      return state.certs
    },
    successText: state => {
      return state.successText
    },
    rules: state => {
      return state.rules
    },
    personalData: state => {
      return state.personalData
    },
    policies: state => {
      return state.policies
    },
    rating: state => {
      return state.rating
    },
    voucherData: state => {
      return state.voucherData
    },
    unregisteredPhone: state => {
      return state.unregisteredPhone
    },
  }
})
