<template>
  <div class="winners-wrapper section-wrapper">
    <h3 class="section-title">Победители</h3>

    <img src="@/assets/dark-blue-gift.png"
         class="gift-img" />

    <!-- <h5 class="subtext">Смотрите трансляцию подведения итогов розыгрыша</h5> -->

    <div class="winners-content">
      <div class="d-flex content-center w-100 video-wrapper">
        <!-- <video src=""
               controls class="video-frame">
          Sorry, your browser doesn't support embedded videos,
          but don't worry, you can <a href="videofile.ogg">download it</a>
          and watch it with your favorite video player!
        </video> -->

        <!-- <div class="plug">
          Следите за трансляцией подведения <br> итогов на нашем сайте
        </div> -->

        <video src="https://storage.yandexcloud.net/alcon-starfall/Alcon_%20%D0%94%D0%B0%D1%80%D0%B8%D1%82%20%D0%BF%D0%BE%D0%B4%D0%B0%D1%80%D0%BA%D0%B8_%D0%A1%D0%B5%D0%BD%D1%82%D1%8F%D0%B1%D1%80%D1%8C.mp4"
               controls class="video-frame">
          Sorry, your browser doesn't support embedded videos,
          but don't worry, you can <a href="videofile.ogg">download it</a>
          and watch it with your favorite video player!
        </video>
      </div>

      <div class="table-wrapper">
        <p class="table-title">
          Топ-10 участников, претендующих на розыгрыш главных призов
        </p>

        <div class="d-flex align-items-start justify-content-start" v-if="ratingTable">
          <div class="w-100"
               style="max-width: 660px;">
            <div class="mb-3 search-bar">
              <b-form-input id="search-bar"
                            type="search"
                            class="search"
                            @click="putPlaceholder"
                            v-model="searchPhone"
                            invalid-feedback="Некорректный телефон"
                            v-mask="'+7(###)-###-##-##'"
                            maxlength="17"
                            placeholder="Поиск по телефону"
                            v-debounce:400ms="searchWinner"
              >

              </b-form-input>

              <b-button class="button button-search button-white"
                        variant="primary"
                        @click="searchWinner">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.44 15.525L11.632 11.5645C12.6111 10.4005 13.1475 8.93609 13.1475 7.41153C13.1475 3.84955 10.2495 0.951538 6.68753 0.951538C3.12555 0.951538 0.227539 3.84955 0.227539 7.41153C0.227539 10.9735 3.12555 13.8715 6.68753 13.8715C8.02475 13.8715 9.29906 13.4682 10.3885 12.7025L14.2255 16.6931C14.3859 16.8597 14.6016 16.9515 14.8327 16.9515C15.0515 16.9515 15.2591 16.8681 15.4167 16.7165C15.7515 16.3943 15.7621 15.8601 15.44 15.525ZM6.68753 2.63675C9.3204 2.63675 11.4623 4.77866 11.4623 7.41153C11.4623 10.0444 9.3204 12.1863 6.68753 12.1863C4.05466 12.1863 1.91275 10.0444 1.91275 7.41153C1.91275 4.77866 4.05466 2.63675 6.68753 2.63675Z" fill="#003595"/>
                  </svg>
              </b-button>
            </div>
          </div>
        </div>

        <div v-if="ratingTable">
          <div class="table-header">
            <div>Место</div>

            <div>Имя участника</div>

            <div>Телефон</div>
          </div>

          <div class="table-line"
               v-for="item in rating">
            <div>{{ item.place }}</div>

            <div>{{ item.name }}</div>

            <div>{{ item.phone }}</div>
          </div>
        </div>

        <div v-if="winnersTable">
          <div class="switch-tables">
            <div class="option"
                 :class="{ active : firstWinners }"
                 @click="showFirstWinners">
              8 сентября
            </div>

            <div class="option"
                 :class="{ active : secondWinners }"
                 @click="showSecondWinners">
              6 октября
            </div>
          </div>

          <div class="" v-if="firstWinners">
            <div class="table-header">
              <div>Имя участника</div>

              <div>Телефон</div>

              <div>Приз</div>
            </div>

            <div class="table-line">
              <div>Юлия</div>

              <div>+79*****3257</div>

              <div>Стайлер Dyson</div>
            </div>

            <div class="table-line">
              <div>Евгений</div>

              <div>+79*****2447</div>

              <div>Стайлер Dyson</div>
            </div>

            <div class="table-line">
              <div>АННА</div>

              <div>+79*****3086</div>

              <div>Стайлер Dyson</div>
            </div>
          </div>

          <div class="" v-if="secondWinners">
            <div class="table-header">
              <div>Имя участника</div>

              <div>Телефон</div>

              <div>Приз</div>
            </div>

            <div class="table-line">
              <div>Елена</div>

              <div>+79*****1086</div>

              <div>Стайлер Dyson</div>
            </div>

            <div class="table-line">
              <div>Мария</div>

              <div>+79*****8832</div>

              <div>Стайлер Dyson</div>
            </div>

            <div class="table-line">
              <div>Андрей</div>

              <div>+79*****4877</div>

              <div>Стайлер Dyson</div>
            </div>
          </div>
        </div>

        <div>
          <p v-if="ratingTable" @click="switchTables" class="switch">Все победители</p>

          <p v-if="winnersTable" @click="switchTables" class="switch">Топ-10 участников, претендующих на розыгрыш </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
  name: 'WinnersView',

  components: {
  },

  data: () => ({
    searchPhone: '',
    noResults: false,
    winnersTable: false,
    ratingTable: true,
    firstWinners: true,
    secondWinners: false,
  }),

  computed: {
    ...mapGetters({
      rating: 'rating',
    }),
  },

  methods: {
    ...mapMutations({
      setRating: 'setRating',
    }),

    switchTables(){
      this.winnersTable = !this.winnersTable
      this.ratingTable = !this.ratingTable
    },

    putPlaceholder(){
      if (this.searchPhone.length < 1){
        this.searchPhone = '+7'
      }
    },

    showFirstWinners(){
      this.firstWinners = true
      this.secondWinners = false
    },

    showSecondWinners(){
      this.firstWinners = false
      this.secondWinners = true
    },

    searchWinner(){
      this.$http.get('/rating?per_page=10&phone='+ this.searchPhone
          .split('+').join('')
          .split(' ').join('')
          .split('(').join('')
          .split(')').join('')
          .split('+').join('-') + '')
          .then((response) => {

            this.setRating(response.data.data)

            if (this.rating.length < 1) {
              this.noResults = true
            }
          }).catch(()=>{
              this.setRating()
      })
    },
  }
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-color: $darker-blue;
  background-image: url('@/assets/winners-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 150px;
  background-size: cover;
  background-position: center center;
  color: $white;
  min-height: 100vh;

  @media(max-width: 999px){
    padding-bottom: 70px;
    padding-top: 100px;
  }
}

h3 {
  @media(max-width: 768px){
    text-align: center;
  }
}

.subtext {
  font-weight: 400 !important;
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 70px;

  @media(max-width: 768px){
    margin-bottom: 30px;
    text-align: left;
  }
}

.winners-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media(max-width: 768px){
    flex-direction: column;
  }
}

.table-wrapper {
  width: 100%;
  max-width: 900px;
  margin-left: 20px;

  @media(max-width: 1600px){
    max-width: 800px;
  }

  @media(max-width: 768px){
    margin-top: 20px;
    font-size: 12px;
    margin-left: 0;
  }

  .table-header {
    font-weight: 600;
    background-color: white;
    color: $darker-blue;
    text-align: center;
    font-family: $regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media(max-width: 768px){
      font-size: 12px;
    }
  }

  .table-line {

    &:nth-child(3){
      background-color: rgba(0, 53, 149, 0.2);
    }
  }

  .table-line,
  .table-header {
    height: 57px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 57px;
    padding: 0 15px;

    div {
      text-align: center;
      width: 33%;
    }
  }
}

.video-frame {
  max-width: 790px;
  max-height: 800px;
  width: 100%;
  height: 100%;

  @media(max-width: 1600px){
    max-width: 490px;
    max-height: 500px;
  }

  @media(max-width: 768px){
    max-height: 250px;
    margin-bottom: 20px;
  }
}

video:-webkit-full-page-media {
  margin: 0 !important;
}

.section-title {
  margin-bottom: -35px;

  @media(max-width: 999px){
    text-align: left;
    font-size: 36px;
  }
}

.plug {
  margin-top: 100px;
  background-color: #141A28;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(100,100,100,0.7);
  border-radius: 20px;
  padding: 160px 190px;
  text-align: center;
  flex-shrink: 0;

  @media(max-width: 1600px){
    padding: 110px 90px;
  }

  @media(max-width: 999px){
    padding: 60px 30px;
    margin-right: 15px;
    font-size: 14px;
    white-space: nowrap;
  }
}

.table-title {
  font-weight: 700;
  font-size: 30px;
  font-family: $regular;
  line-height: 120%;
  margin-bottom: 30px;
  position: relative;
  z-index: 3;

  @media(max-width: 999px){
    font-size: 20px;
  }
}

.content-center {
  @media(max-width: 576px){
    justify-content: center;
  }
}

.search {
  border-radius: 95px;
  height: 50px;
  background-color: transparent;
  border: 2px solid $white;
  padding-left: 22px;
  color: white !important;

  &::placeholder {
    color: #869BC7;
  }

  &:active,
  &:focus {
    box-shadow: none !important;
    outline: none !important;
    border: 2px solid rgba(255, 255, 255, 0.6) !important;
    background: transparent;
  }
}

.search-bar {
  display: flex;
  align-items: center;
  max-width: 350px;
  position: relative;

  @media(max-width: 999px){
    flex-direction: column;

    .search {
      margin-bottom: 20px;
    }
  }
}

.button-search {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 100;
  right: 0;
  margin-right: 10px;

  @media(max-width: 768px){
    margin-top: 7px;
  }
}

.gift-img {
  position: absolute;
  right: 0;
  top: -85px;
  max-width: 260px;
  z-index: 1;

  @media(max-width: 576px){
    max-width: 100px;
    top: 70px;
  }
}

.video-wrapper {
  margin-top: 105px;

  @media(max-width: 576px){
    margin-top: 70px;
  }
}

.switch {
  text-decoration: underline;
  font-weight: 600;
  margin-top: 50px;
  cursor: pointer;
  font-family: $regular;

  &:hover {
    opacity: 0.85;
  }
}

.switch-tables {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .option {
    margin: 0 15px;
    cursor: pointer;
    transition: 0.6s all;
    font-family: $regular;
    font-weight: 600;
    font-size: 18px;

    &:hover {
      opacity: 0.8;
    }

    &.active {
      text-decoration: underline;
    }
  }
}
</style>
