<template>
  <div id="app">
    <HeaderRegion />

    <router-view />

    <Feedback />

    <Error />

    <Sample />

    <Upload />

    <Success />

    <CodeCheck />

    <UploadVoucher />

    <FailedVoucher />

    <ManualVoucher />

    <SuccessUpload />

    <FooterRegion />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from "vuex"
import Error from '@/components/modals/Error.vue'
import Upload from '@/components/modals/Upload.vue'
import Sample from '@/components/modals/Sample.vue'
import Success from '@/components/modals/Success.vue'
import Feedback from '@/components/modals/Feedback.vue'
import HeaderRegion from '@/components/HeaderRegion.vue'
import FooterRegion from '@/components/FooterRegion.vue'
import CodeCheck from '@/components/modals/CodeCheck.vue'
import FailedVoucher from '@/components/modals/FailedVoucher.vue'
import UploadVoucher from '@/components/modals/UploadVoucher.vue'
import SuccessUpload from '@/components/modals/SuccessUpload.vue'
import ManualVoucher from '@/components/modals/ManualVoucher.vue'

export default {
  components: {
    Error,
    Sample,
    Upload,
    Success,
    Feedback,
    CodeCheck,
    HeaderRegion,
    FooterRegion,
    ManualVoucher,
    FailedVoucher,
    UploadVoucher,
    SuccessUpload,
  },

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    }),
  },

  mounted(){
    this.$store.dispatch('getDocuments')
    this.$store.dispatch('getRating')

    if(this.token){
      this.$store.dispatch('getUser')
      this.$store.dispatch('getCerts')

      axios.defaults.headers.authorization = "Bearer " + this.token
    }
  },

  methods: {
  },
}
</script>

<style lang="scss">

</style>
