<template>
  <section class="section-wrapper"
           id="promo">
    <div class="wrapper">
      <div class="promo-block">
        <div class="">
          <h1 class="promo-title">подарки <br> от alcon</h1>

          <router-link class="button button-orange mt-4"
                       :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'">
            Участвовать
          </router-link>
        </div>
      </div>
    </div>

    <img src="@/assets/gifts.png"
         class="gifts gifts__tablet" />
  </section>
</template>

<script>

export default {
  name: 'Promo',

  data: () => ({
  }),

  computed: {
  },
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-color: $darker-blue;
  background-repeat: no-repeat;
  padding: 0;
  background-position: center right;
  color: $white;
  height: calc(100vh - 68px);
  background-image: url('@/assets/visual-bg.webp');
  background-size: cover;
  padding-top: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $regular;

  @media(max-width: 1200px){
    background-image: url('@/assets/bg-promo.png');
    padding-left: 60px;
    padding-right: 60px;
  }

  @media(max-width: 999px){
    padding-left: 35px;
    padding-right: 35px;
    align-items: flex-start;
    padding-top: 120px;
    background-image: url('@/assets/visual-bg.webp');
  }

  @media(max-width: 768px){
    align-items: flex-start;
    background-image: url('@/assets/visual-bg@mobile.webp');
    background-size: cover;
    background-position: right bottom;
    height: calc(100vh - 28px);
  }

  @media(max-width: 576px){
    padding-left: 20px;
    padding-top: 90px;
  }

  @media(max-width: 470px){
    background-position: bottom left -28px;
    padding-top: 110px;
  }

  @media(max-width: 420px){
    background-position: bottom left;
  }
}

.gifts {
  position: absolute;
  display: none;

  &__tablet {
    right: 0;
    z-index: 0;
    max-height: 92%;

    @media(max-width: 1200px){
      display: block;
    }

    @media(max-width: 999px){
      display: none;
    }
  }
}

.wrapper {
  max-width: 1400px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0;

  @media(max-width: 1600px){
    max-width: 1200px;
  }
}

.promo-block {
  display: flex;
  align-items: center;
  height: fit-content;

  .img {
    max-width: 490px;
    margin-right: 35px;
  }

  .promo-title {
    font-weight: 700;
    font-size: 82px;
    text-transform: uppercase;
    font-family: $regular;
    z-index: 2;
    position: relative;

    @media(max-width: 1600px){
      font-size: 70px;
    }

    @media(max-width: 999px){
      font-size: 40px;
    }

    @media(max-width: 420px){
      margin-bottom: 0;
    }

    @media(max-width: 350px){
      font-size: 32px;
    }
  }

  .button {
    min-width: 250px;

    &:hover {
      text-decoration: none;
    }

    @media(max-width: 999px){
      min-width: 215px;
    }

    @media(max-width: 420px){
      min-width: 210px;
      height: 50px;
      margin-top: 15px !important;
    }

    @media(max-width: 350px){
      min-width: 185px;
      height: 45px;
      margin-top: 15px !important;
    }
  }
}
</style>
