<template>
  <div>
    <b-modal
      id="feedback-modal"
      ref="modal"
      title=""
      hide-footer
      hide-header
    >
      <div class="header">
        <span class="close-icon"
              @click="closeModal">
          <img src="@/assets/svg/close.svg"
               aria-label="close">
        </span>

        <h3>Обратная связь</h3>
      </div>

      <form ref="form"
            class="modal-form"
            @submit.stop.prevent="submit">
          <b-form-group
            id="name-input"
            label-for="name-input"
            invalid-feedback="Некорректное имя"
          >
            <b-form-input
              id="name-input"
              placeholder="Имя"
              required
              type="text"
              pattern="[A-Za-z]+"
              v-model="$v.item.name.$model"
              :state="validateState($v.item.name)"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-for="phone-input"
            invalid-feedback="Некорректный телефон"
            v-mask="'+7(###)-###-##-##'"
          >
            <b-form-input
              @click="putPlaceholder"
              id="phone-input"
              placeholder="Телефон"
              v-model="$v.item.phone.$model"
              maxlength="17"
              :state="validateState($v.item.phone)"
              required
            ></b-form-input>
         </b-form-group>

         <b-form-group
           label-for="email-input"
           invalid-feedback=""
         >
           <b-form-input
             id="email-input"
             placeholder="Почта"
             v-model="$v.item.email.$model"
             :state="validateState($v.item.email)"
             required
           ></b-form-input>
        </b-form-group>

        <b-form-group
          class="position-relative"
          id="select-input"
          label-for="select-input"
          invalid-feedback="Выберите тему обращения"
        >
          <b-form-input
            readonly
            id="select-input"
            @click="list = !list"
            placeholder="Тема обращения"
            required
            class="select mb-0"
            :class="{ active: list }"
            type="text"
            v-model="$v.item.subject.$model"
            :state="validateState($v.item.subject)"
          ></b-form-input>

          <div class="select-list"
               v-if="list">
            <div class=""
                 v-for="option in options"
                 :key="option.name"
                 @click="setSubject(option.name)"
                 ref="optionName">
              {{ option.name }}
            </div>
          </div>

          <img src="@/assets/svg/arrow-down.svg"
               class="select-icon"
               @click="list = !list"
               v-if="!list" />

          <img src="@/assets/svg/arrow-top.svg"
               class="select-icon"
               @click="list = !list"
               v-if="list" />
        </b-form-group>

        <b-form-group
          class="position-relative"
          id="textarea"
          label-for="textarea"
          invalid-feedback="Опишите проблему"
        >
          <b-form-textarea
            id="textarea"
            placeholder="Текст обращения"
            rows="3"
            class="textarea"
            max-rows="6"
            invalid-feedback="Опишите проблему"
            v-model="$v.item.msg.$model"
            :state="validateState($v.item.msg)"
            required
          ></b-form-textarea>
        </b-form-group>

        <div class="w-100 d-flex justify-content-center captcha">
          <vue-recaptcha sitekey="6LdpiVEnAAAAAF7VNKFJWmbOlidgmi7j1a3ZPRir"
                         :loadRecaptchaScript="true"
                         class="captcha"
                         size="100%"
                         ref="recaptcha"
                         @verify="onCaptchaVerified"
                         v-model="$v.item.recaptchaResponse.$model"
                         :state="validateState($v.item.recaptchaResponse)"
          ></vue-recaptcha>
        </div>
      </form>

      <div class="button-wrapper">
        <button class="button button-white"
                type="submit"
                onclick="ym(94436170,'reachGoal','feedback')"
                :class="{ 'disabled' : disabledBtn }"
                :disabled="disabledBtn"
                @click="submit">
          Отправить
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import VueRecaptcha from 'vue-recaptcha'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const text = helpers.regex('text', /^[?!,.а-яА-ЯёЁ\s]+$/)
const number = helpers.regex('number', /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,999}$/)

  export default {
    props: ['thanksText'],

    components: {
      VueRecaptcha
    },

    mixins: [validationMixin],

    validations: {
      item: {
        name: {
          required,
          minLength: minLength(2),
          text,
        },
        phone: {
          required,
          minLength: minLength(17),
          maxLength: maxLength(17),
        },
        email: {
          required,
          minLength: minLength(2),
          email
        },
        msg: {
          required,
          minLength: minLength(10)
        },
        subject: {
          required,
        },
        recaptchaResponse: {

        }
      }
    },

    data() {
      return {
        item: {
          name: '',
          phone: '',
          msg: '',
          subject: '',
          email: '',
          recaptchaResponse: ''
        },
        recaptcha: null,
        list: '',
        options: [
          { value: 'Вопрос по акции', name: 'Вопрос по акции' },
          { value: 'Вопрос по призам', name: 'Вопрос по призам' },
          { value: 'Вопрос по правилам', name: 'Вопрос по правилам' },
        ],
      }
    },

    mounted() {
      axios.get('feedback/topics', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
        }
      }).then((response) => {
        this.options = response.data.data
      })
    },

    computed: {
      disabledBtn(){
        if ((this.$v.item.$anyError) || (this.item.name.length < 1) || (this.item.email.length < 1) || (this.item.phone.length < 1) || (this.item.msg.length < 1))
          return true
      }
    },

    methods: {
      ...mapMutations({
        getSuccessText: 'getSuccessText',
        getErrorText: 'getErrorText'
      }),

      resetModal() {
        this.$v.$reset()

        this.item.name = '',
        this.item.phone = '',
        this.item.email = '',
        this.item.msg = '',
        this.item.subject = null,
        this.item.recaptchaResponse = ''

        this.$bvModal.hide('feedback-modal')

        this.$bvModal.show('thanks-modal')
        this.getSuccessText("Ваше обращение принято!")
      },

      closeModal(){
        this.$v.$reset()

        this.item.name = '',
        this.item.phone = '',
        this.item.email = '',
        this.item.msg = '',
        this.item.subject = null,
        this.item.recaptchaResponse = ''

        this.$bvModal.hide('feedback-modal')
      },

      validateState(item) {
        const { $dirty, $error } = item
        return $dirty ? !$error : null
      },

      putPlaceholder(){
        if (this.item.phone.length < 3){
          this.item.phone = '+79'
        }
      },

      setSubject(optionName) {
        this.item.subject = optionName

        this.list = false
      },

      onCaptchaVerified(recaptchaToken) {
        this.item.recaptchaResponse = recaptchaToken
      },

      submit() {
        this.$v.item.$touch()

        if (this.$v.item.$anyError)
          return false

        axios.post('feedback/send', {
          name: this.item.name,
          phone: this.item.phone,
          email: this.item.email,
          subject: this.item.subject,
          text: this.item.msg,
          "g-recaptcha-response": this.item.recaptchaResponse
        }).then((response) => {
          this.resetModal()

          this.$bvModal.show('success-modal')
        }).catch((error) => {
          if (error.response.data.message){
            this.getErrorText(error.response.data.message)

            this.closeModal()
            this.$bvModal.show('error-modal')
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.textarea {
  margin-top: 20px;
  min-height: 100px;
  padding: 15px 25px;
  border-radius: 20px !important;

  &::placeholder {
    color: #6882B9 !important;
  }
}

h3 {
  text-align: center;
}

.form-control {
  color: $white !important;
  height: 50px;

  &::placeholder {
    color: #6882B9 !important;
  }
}

.dropdown-button {
  width: 100%;
  background-color: $darker-blue !important;
}

.select {
  height: 50px;
  width: 100%;
  position: relative;
  background-color: transparent;
  margin-bottom: 20px;
  margin-top: 5px;
  transition: .1s all !important;
  border: 2px solid white;

  &.active {
    border: 2px solid white;
    border-bottom: none !important;
    border-radius: 25px 25px 0 0 !important;
    background-color: $darker-blue !important;
  }
}

.select-icon {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  margin-top: 10px;
}

.select-list {
  position: absolute;
  margin-top: -10px;
  width: 100%;
  z-index: 99999;
  border-top: none;
  border-radius: 0 0 25px 25px;
  background-color: $darker-blue;

  div {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    background-color: $darker-blue;
    cursor: pointer;
    transition: .5s all;
    font-family: $regular;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: $white;
    border: 2px solid white !important;

    &:first-child {
      border-top: none !important;
    }

    &:last-child {
      border-radius: 0 0 25px 25px;
    }

    &.active {
      background-color: $darker-blue;
      color: $white;
    }

    &:hover {
      opacity: .8;
    }
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  max-width: 400px;

  .button {
    width: 100%;
  }
}

.inputs-wrapper {
  justify-content: space-between;

  div {
    width: calc(100% - 20px);
    max-width: 350px !important;
  }
}

.modal-form {
  max-width: 400px;
  padding: 0 !important;
}

.button {
  margin-top: 10px;
  width: 100%;

  &.disabled {
    pointer-events: none;
    color: #6882B9;
  }

  &:hover {
    opacity: 0.8 !important;
  }
}
</style>
