<template>
  <div class="w-100"
       id="registration">
    <div class="phone-login">
      <form ref="form"
            class="modal-form"
            style="margin-left: -8px; margin-top: 20px;"
            @submit.stop.prevent="submit">
        <div class="">
          <div class="position-relative"
               v-if="!unregisteredPhone">
            <b-form-group
              label-for="phone-input"
               invalid-feedback=""
               v-mask="'+7(###)-###-##-##'"
            >
              <b-form-input
                @click="putPlaceholder"
                id="phone-input"
                placeholder="+7 (___) ___-__-__"
                v-model="$v.item.phone.$model"
                maxlength="17"
                :state="validateState($v.item.phone)"
                required
            ></b-form-input>
            </b-form-group>

            <div class="error"
                 v-if="invalidPhone">
              {{ phoneError }}
            </div>
          </div>

          <div class="position-relative unregistered"
               v-if="unregisteredPhone">
            <b-form-group
              label-for="phone-input"
               invalid-feedback=""
               v-mask="'+7(###)-###-##-##'"
            >
              <b-form-input
                @click="putPlaceholder"
                id="phone-input"
                placeholder="+7 (___) ___-__-__"
                v-model="$v.item.phone.$model"
                maxlength="17"
                readonly
                :state="validateState($v.item.phone)"
                required
            ></b-form-input>
            </b-form-group>

            <div class="error"
                 v-if="invalidPhone">
              {{ phoneError }}
            </div>
          </div>

          <div class="position-relative">
            <b-form-group
              label-for="email-input"
              invalid-feedback=""
            >
              <b-form-input
                id="email-input"
                placeholder="Введите e-mail"
                v-model="$v.item.email.$model"
                :state="validateState($v.item.email)"
                required
              ></b-form-input>
           </b-form-group>

           <div class="error"
                style="margin-top: -18px;"
                v-if="invalidEmail">
             {{ emailError }}
           </div>
         </div>
        </div>

        <div class="">
          <div class="position-relative height"
               :class="{ 'addHeight' : (invalidName || invalidSurname) }">
            <b-form-group
              class="position-relative"
              label-for="name-input"
              :invalid-feedback="nameError"
            >
              <b-form-input
                id="name-input"
                placeholder="Ваше имя"
                v-model="$v.item.name.$model"
                :state="validateState($v.item.name)"
                required
            ></b-form-input>
            </b-form-group>

            <div class="error"
                 v-if="invalidName">
              {{ nameError }}
            </div>
          </div>

          <div class="position-relative height"
               :class="{ 'addHeight' : (invalidName || invalidSurname) }">
            <b-form-group
              class="position-relative"
              label-for="surname-input"
              :invalid-feedback="surnameError"
            >
              <b-form-input
                id="surname-input"
                placeholder="Ваша фамилия"
                v-model="$v.item.surname.$model"
                :state="validateState($v.item.surname)"
                required
              ></b-form-input>

              <div class="error"
                   style="margin-top: -10px;"
                   v-if="invalidSurname">
                {{ surnameError }}
              </div>
           </b-form-group>
         </div>
        </div>

        <div class="">
          <div class="position-relative">
            <b-form-group
              label-for="password-input"
              invalid-feedback=""
            >
              <b-form-input
                id="password-input"
                placeholder="Введите пароль"
                v-model="$v.item.password.$model"
                :state="validateState($v.item.password)"
                required
                type="password"
              ></b-form-input>
           </b-form-group>

           <div class="error"
                style="margin-top: -15px;"
                v-if="invalidPassword">
             {{ passwordError }}
           </div>
         </div>

         <div class="position-relative">
            <b-form-group
             label-for="password-input"
             invalid-feedback=""
             >
             <b-form-input
               id="password-input"
               placeholder="Подтвердите пароль"
               v-model="$v.item.passwordConfirmation.$model"
               :state="validateState($v.item.passwordConfirmation)"
               required
               type="password"
             ></b-form-input>
           </b-form-group>

           <div class="error"
                v-if="invalidPassword">
             {{ passwordError }}
           </div>
         </div>
        </div>
      </form>

      <div class="" style="display: flex;flex-direction: column;align-items: center;">
        <b-form-checkbox
          id="checkbox-policies"
          class="mt-2 checkbox desktop"
          v-model="$v.item.policies.$model"
          :state="validateState($v.item.policies)"
          name="policies"
          required
          v-if="personalData"
        >
        Я даю согласие на обработку своих персональных данных <br> в соответствии с
          <a :href="personalData[0].url"
             target="_blank">Политикой в отношении обработки <br> персональных данных ООО “Алкон Фармацевтика”</a>
        </b-form-checkbox>

        <b-form-checkbox
          id="checkbox-policies"
          class="mt-2 checkbox mobile"
          v-model="$v.item.policies.$model"
          :state="validateState($v.item.policies)"
          name="policies"
          required
          v-if="personalData"
        >
        Я даю согласие на обработку своих персональных данных в соответствии с
          <a :href="personalData[0].url"
             target="_blank">Политикой в отношении обработки персональных данных ООО “Алкон Фармацевтика”</a>
        </b-form-checkbox>

        <b-button class="button button-white"
                  type="submit"
                  onclick="ym(94436170,'reachGoal','registration')"
                  :disabled="disabledBtn"
                  @click="submit">Зарегистрироваться</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const text = helpers.regex('text', /^[?!,.а-яА-ЯёЁ\s]+$/)
const email = helpers.regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,999}$/)

export default {
  name: 'Registration',

  mixins: [validationMixin],

  validations: {
    item: {
      phone: {
        required,
      },
      email: {
        required,
        minLength: minLength(2),
        email
      },
      password: {
        required,
      },
      passwordConfirmation: {
        required,
      },
      name: {
        text,
        minLength: minLength(2),
        required
      },
      surname: {
        text,
        minLength: minLength(2),
        required
      },
      policies: {
        required
      }
    }
  },

  components: {
  },

  data() {
    return {
      item: {
        phone: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        name: '',
        surname: '',
        policies: ''
      },
      invalidPassword: false,
      invalidEmail: false,
      invalidPhone: false,
      invalidName: false,
      invalidSurname: false,
      phoneError: 'Некорректное поле',
      emailError: 'Некорректное поле',
      passwordError: 'Некорректное поле',
      nameError: 'Некорректное поле',
      surnameError: 'Некорректное поле'
    }
  },

  watch: {
    unregisteredPhone(){
      if(this.unregisteredPhone.length > 1){
        console.log(this.unregisteredPhone)

        this.item.phone = this.unregisteredPhone
      }
    }
  },

  mounted(){
    this.item.phone = this.unregisteredPhone
  },

  computed: {
    ...mapGetters({
      unregisteredPhone: 'unregisteredPhone',
      personalData: 'personalData'
    }),

    disabledBtn(){
      if ((this.$v.item.$anyError) || (this.item.phone.length < 1) || (this.item.email.length < 1) || (this.item.name.length < 1) || (this.item.surname.length < 1) || (this.item.password.length < 1))
        return true
    }
  },

  methods: {
    ...mapMutations({
      saveToken: 'saveToken',
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText'
    }),

    putPlaceholder(){
      if (this.item.phone.length < 3){
        this.item.phone = '+79'
      }
    },

    validateState(item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },

    submit() {
      this.$v.item.$touch()

      if (this.$v.item.$anyError)
        return false

      axios.post('auth/register', {
        phone: this.item.phone,
        surname: this.item.surname,
        name: this.item.name,
        email: this.item.email,
        password: this.item.password,
        password_confirmation: this.item.passwordConfirmation,
        accept_policies: this.item.policies
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
        }
      }).then((response) => {
        this.saveToken(response.data.access.token)
        localStorage.setItem('token', response.data.access.token)
        this.$store.dispatch('getUser')
        this.$store.dispatch('getCerts')

        this.getSuccessText("Регистрация прошла успешно")
        this.$bvModal.show('success-modal')
      }).catch((error) => {
        if ((error.response.data.errors.phone) && (error.response.data.errors.email)){
          this.invalidEmail = true
          this.invalidPhone = true
          this.phoneError = error.response.data.errors.phone[0]
          this.emailError = error.response.data.errors.email[0]
        }

        if ((error.response.data.errors.phone)){
          this.invalidPhone = true
          this.phoneError = error.response.data.errors.phone[0]
        }

        if ((error.response.data.errors.name)){
          this.invalidName = true
          this.nameError = error.response.data.errors.name[0]
        }

        if ((error.response.data.errors.surname)){
          this.invalidSurname = true
          this.surnameError = error.response.data.errors.surname[0]
        }

        if ((error.response.data.errors.email)){
          this.invalidEmail = true
          this.emailError = error.response.data.errors.email[0]
        }

        if (error.response.data.errors.password){
          this.invalidPassword = true
          this.passwordError = error.response.data.errors.password[0]
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.form-group {
  position: relative;
  font-family: $regular !important;

  .input-icon {
    position: absolute;
    color: $red;
    font-size: 18px;
    font-family: $regular;
    top: 0;
    margin-left: 15px;
    margin-top: 12px;
  }
}

.form-control {
  color: $white !important;
  background-color: transparent !important;
  border: 1px solid transparent;
  transition: .6s all;
  height: 58px;
  display: flex;
  padding-left: 25px !important;
  align-items: center;
  font-size: 18px;
  margin-top: 5px;
  border-radius: 60px;
  max-width: 340px;
  min-width: 340px;
  margin: 0 8px;
  margin-bottom: 10px;
  font-family: $regular;
  border: 2px solid white;
  box-shadow: 0px 7px 20px 2px rgba(103, 103, 103, 0.1) !important;

  &::placeholder {
    color: #6882B9 !important;
  }

  &:focus {
    box-shadow: none;
    border: 1px solid $darker-blue;
  }

  @media(max-width: 999px){
    min-width: 280px;
    max-width: 300px;
    height: 48px !important;
  }

  @media(max-width: 400px){
    max-width: 250px;
    min-width: 230px;
  }

  @media(max-width: 350px){
    min-width: 200px;
  }
}

.checkbox {
  margin-left: 15px;
  color: $white !important;

  @media(max-width: 999px){
    margin-left: 0;
  }
}

.button {
  width: 100%;
  max-width: 347px;
  border-radius: 95px;
  margin-top: 20px;
  height: 48px;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  @media(max-width: 999px){
    margin-left: 0;
    margin-top: 15px;
    max-width: 300px;
    height: 48px;
  }

  @media(max-width: 400px){
    max-width: 250px;
    min-width: 230px;
  }

  @media(max-width: 350px){
    min-width: 200px;
  }
}

.login-option {
  font-family: $regular;
  cursor: pointer;
  margin-top: 15px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  max-width: 340px;
  text-decoration: underline;
}

.error {
  margin-top: -22px;
  width: 100%;
  max-width: 310px;
  text-align: left;
  font-size: 7px;
  color: white;
  position: absolute;
  font-family: $regular;
  margin-left: 35px;
}

.d-flex {
  @media(max-width: 999px){
    flex-direction: column !important;
  }

  &.align-items-center {
    @media(max-width: 999px){
      align-items: flex-start !important;
    }
  }
}

.addHeight {
  height: 95px;

  @media(max-width: 768px){
    height: 95px;
  }
}

.height {

}

.form-group {
  display: flex;
  justify-content: center;
}
</style>
